import React from "react";

const AdminFooter = () => {
  return (
    <div className="bg-[#f5f5f5]  text-center ">
      All rights reserved @ 2023  CMC
    </div>
  );
};

export default AdminFooter;
