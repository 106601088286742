import React from "react";
import SweingFuncTemaplate from "./SweingFuncTemaplate";

const SweingFucntion2 = ({ heding, data }) => {
  return (
    <div className="flex flex-col gap-16  max-sm:gap-10  p-24 max-xl:p-16 max-sm:p-3 mt-10">
      <h1 className="text max-sm:text-[40px] text-center">{heding}</h1>
      <div className="flex flex-col gap-12 px-16 max-lg:px-10">
        <div className="grid grid-cols-2 justify-around gap-20 max-xl:flex-col max-md:grid-cols-1 ">
          {data.slice(0, 2).map((item) => {
            return (
              <div className="w-[600px] max-xl:w-full">
                <div>
                  <h1 className="list w-[560px] text-[22px] max-xl:w-full border-b-[3px] pb-3 border-[#D9D9D9]">
                    {" "}
                    {item.title}
                  </h1>
                  <ul className="list-disc px-3 my-4  ">
                    <li className="para ">{item.point1}</li>
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-2 justify-around gap-20 max-xl:flex-col max-md:grid-cols-1  ">
          {data.slice(2, 4).map((item) => {
            return (
              <div className="w-[600px] max-xl:w-full">
                <div>
                  <h1 className="list w-[560px] text-[22px] max-xl:w-full border-b-[3px] pb-3 border-[#D9D9D9] ">
                    {" "}
                    {item.title}
                  </h1>
                  <ul className="list-disc px-3 my-4  ">
                    <li className="para ">{item.point1}</li>
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-2 justify-around items-center gap-20 max-xl:flex-col max-md:grid-cols-1">
          {data.slice(4, 6).map((item) => {
            return (
              <div className="w-[600px] max-xl:w-full">
                <div>
                  <h1 className="list w-[560px] text-[22px] max-xl:w-full border-b-[3px] pb-3 border-[#D9D9D9]">
                    {" "}
                    {item.title}
                  </h1>
                  <ul className="list-disc px-3 my-4  ">
                    <li className="para ">{item.point1}</li>
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-2 justify-around gap-20 max-xl:flex-col max-md:grid-cols-1">
          {data.slice(6, 8).map((item) => {
            return (
              <div className="w-[600px] max-xl:w-full">
                <div>
                  <h1 className="list w-[560px] text-[22px] max-xl:w-full border-b-[3px] pb-3 border-[#D9D9D9]">
                    {" "}
                    {item.title}
                  </h1>
                  <ul className="list-disc px-3 my-4  ">
                    <li className="para ">{item.point1}</li>
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SweingFucntion2;
