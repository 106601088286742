import React from "react";
import MainLayout from "../Main";
import AdminMain from "./AdminMain";

const AdminDashBoard = () => {
  return (
    <AdminMain>
      <div className="h-full bg-[#f5f5f5] p-10 text-2xl">DashBoard</div>
    </AdminMain>
  );
};

export default AdminDashBoard;
